import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import styles from "./Popular.module.css";
import axios from "axios";

const Popular = () => {
  const [popNews, setPopNews] = useState(null);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASEURL}/popular-index`)
      .then((res) => {
        setPopNews(res.data.data);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <>
      <h3 className={styles.sectionTitle}>
        <span>Berita Terpopuler</span>
      </h3>
      <ul className={styles.trendingList}>
        {popNews &&
          popNews.map((news, i) => {
            return (
              <li key={i} className="trending-list__li">
                <Link to={`/admin/news/${news.id}`}>{news.title}</Link>
              </li>
            );
          })}
      </ul>
    </>
  );
};

export default Popular;
