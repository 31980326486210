import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import ProtectedRouter from './ProtectedRouter'

import Home from '../home/Home'
import Login from '../login/Login'
import Profile from '../admin/profile/Profile'
import CreateArticle from '../admin/news/create/Create'
import ShowArticle from '../admin/news/show/Show'
import EditArticle from '../admin/news/edit/Edit'
import Gallery from '../admin/gallery/Gallery'
import Ads from '../admin/ads/Ads'
import ShowAds from '../admin/ads/ShowAds'
import EditAds from '../admin/ads/edit/EditAds'
import CreateAds from '../admin/ads/create/CreateAds'

const MainRouter = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/login" component={Login} />
        <ProtectedRouter path="/admin/profile" component={Profile} />
        <ProtectedRouter path="/admin/create-article" component={CreateArticle} />
        <ProtectedRouter path="/admin/news/edit/:id" component={EditArticle} />
        <ProtectedRouter path="/admin/news/:id" component={ShowArticle} />
        <ProtectedRouter path="/admin/img-gallery" component={Gallery} />
        <ProtectedRouter path="/admin/ads/show/:id" component={ShowAds} />
        <ProtectedRouter path="/admin/ads/edit/:id" component={EditAds} />
        <ProtectedRouter path="/admin/ads/create" component={CreateAds} />
        <ProtectedRouter path="/admin/ads" component={Ads} />
      </Switch>
    </BrowserRouter>
  )
}

export default MainRouter
