import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import styles from './Detail.module.css'

const Detail = (props) => {
  const [ads, setAds] = useState(null)

  useEffect(() => {
    if (props !== undefined) {
      setAds(props.ads)
    }
  }, [props])

  // console.log(ads.img)
  return (
    <>
      {ads !== null && (
        <div className="card">
          <img src={ads.img ? ads.img : ""} className="card-img-top" alt="..." />
          <div className="card-body">
            <h5 className="card-title">{ads.title}</h5>
            <table className={"table " + styles.table}>
              <tbody>
                <tr>
                  <td className={styles.tdFirst}>Jenis Iklan</td>
                  <td style={{ width: "10px" }} className="text-center">:</td>
                  <td>{ads.ads_type.name}</td>
                </tr>
                <tr>
                  <td className={styles.tdFirst}>URL</td>
                  <td style={{ width: "10px" }} className="text-center">:</td>
                  <td><a href={ads.url} target="_blank" rel="noopener noreferrer">{ads.url}</a></td>
                </tr>
                <tr>
                  <td className={styles.tdFirst}>Harga</td>
                  <td style={{ width: "10px" }} className="text-center">:</td>
                  <td>Rp {ads.price_text}</td>
                </tr>
                <tr>
                  <td className={styles.tdFirst}>Tanggal Mulai</td>
                  <td style={{ width: "10px" }} className="text-center">:</td>
                  <td>{ads.start_date}</td>
                </tr>
                <tr>
                  <td className={styles.tdFirst}>Tanggal Selesai</td>
                  <td style={{ width: "10px" }} className="text-center">:</td>
                  <td>{ads.end_date}</td>
                </tr>
                <tr>
                  <td className={styles.tdFirst}>Diklik</td>
                  <td style={{ width: "10px" }} className="text-center">:</td>
                  <td>{ads.hits_text} <small>kali</small></td>
                </tr>
                <tr>
                  <td className={styles.tdFirst}>Nama Marketing</td>
                  <td style={{ width: "10px" }} className="text-center">:</td>
                  <td>{ads.marketing}</td>
                </tr>
                <tr>
                  <td className={styles.tdFirst}>Status</td>
                  <td style={{ width: "10px" }} className="text-center">:</td>
                  <td>{ads.is_active === 1 ? <span className="badge badge-success">Aktif</span> : <span className="badge badge-danger">Tidak Aktif</span>}</td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <Link to={`/admin/ads/edit/${ads.id}`} className={"btn btn-outline-success mt-3"}>Ubah Iklan</Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  )
}

export default Detail
