import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap';
// import axios from 'axios';
import auth from '../../../../auth';
import Header from '../../../../components/admin/header/desktop/Header';

import Edit from './form/Edit';
import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';

const ShowAds = (props) => {
  const [user, setUser] = useState(null)
  const [adsID, setAdsID] = useState(null)
  const [ads, setAds] = useState(null)

  useEffect(() => {
    window.document.title = "Update Iklan | Bearita.com"
    if (auth.user() !== null) {
      setUser(JSON.parse(auth.user()));
    }
  }, [])

  useEffect(() => {
    if (props !== undefined) {
      setAdsID(props.match.params.id)
    }
  }, [props])

  useEffect(() => {
    const cache = setupCache({
      maxAge: 15 * 60 * 1000
    })

    const api = axios.create({
      adapter: cache.adapter
    })

    if (user !== null) {
      api({
        method: 'get',
        url: `${process.env.REACT_APP_BASEURL}/admin/ads/${adsID}`
      }).then(res => {
        setAds(res.data.data)
      }).catch(error => console.log(error))
    }
  }, [user, adsID])

  const handleBack = (() => {
    props.history.push('/admin/ads')
  })

  return (
    <>
      <Header user={user} />
      <Container>
        <Row>
          <Col xs md={12}>
            <Link to={`/admin/ads`} className={"btn btn-outline-secondary my-3"}>Kembali ke Iklan</Link>
            <div className="table-responsive">
              {ads &&
                <Edit handleBack={() => handleBack()} ads={ads} adsID={adsID} />
              }
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ShowAds
