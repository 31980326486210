import React from 'react';
import MainRouter from './containers/router/MainRouter'
require('dotenv').config()

function App() {
  return (
    <MainRouter />
  );
}

export default App;
