import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import TextareaAutosize from "react-textarea-autosize";
import imageCompression from "browser-image-compression";
// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Select from "react-select";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
// import SweetAlert from 'sweetalert2-react'
import { setupCache } from "axios-cache-adapter";
import { Link } from "react-router-dom";

import Datetime from "react-datetime";
import { Editor } from "@tinymce/tinymce-react";

import styles from "./Edit.module.css";
import Header from "../../../../components/admin/header/desktop/Header";
import auth from "../../../../auth";

import SectionDesktopComponent from "../../../../components/admin/news/desktop/edit/Section";

const Edit = (props) => {
  const [user, setUser] = useState(null);
  const [isTitleError, setIsTitleError] = useState(false);
  const [title, setTitle] = useState("");
  const [remainingTitle, setRemainingTitle] = useState(70);
  const [teaser, setTeaser] = useState("");
  const [remainingTeaser, setRemainingTeaser] = useState(60);
  const [mainImage, setMainImage] = useState("");
  const [reviewMainImage, setReviewMainImage] = useState(null);
  const [imgTitle, setImgTitle] = useState("");
  const [content, setContent] = useState("");
  const [isContentError, setIsContentError] = useState(false);
  const [category, setCategory] = useState(null);
  const [isCategoryError, setIsCategoryError] = useState(false);
  const [optionCategories, setOptionCategories] = useState(null);
  // const [isSuccessSave, setIsSuccessSave] = useState(false);
  const [newsID, setNewsID] = useState(null);
  const [news, setNews] = useState(null);
  const [isHeadline, setIsHeadline] = useState(0);
  const [isActive, setIsActive] = useState(0);
  const [labelSubmit, setLabelSubmit] = useState("Simpan Artikel");
  const [sections, setSections] = useState([]);
  const [datePublish, setDatePublish] = useState(null);
  // const [sectionFromBE, setSectionFromBE] = useState([]);

  useEffect(() => {
    if (auth.user() !== null) {
      setUser(JSON.parse(auth.user()));
    }
    setNewsID(props.match.params.id);
  }, [props]);

  useEffect(() => {
    const cache = setupCache({
      maxAge: 15 * 60 * 1000,
    });

    const api = axios.create({
      adapter: cache.adapter,
    });

    if (user !== null) {
      api({
        method: "get",
        url: `${process.env.REACT_APP_BASEURL}/news/${newsID}`,
      })
        .then((res) => {
          setNews(res.data.data);
        })
        .catch((error) => console.log(error));
    }
  }, [user, newsID]);

  useEffect(() => {
    if (news !== null) {
      setTitle(news.title);
      setTeaser(news.teaser);
      setReviewMainImage(news.img_sm);
      setContent(news.content);
      setCategory(news.category.id);
      setIsHeadline(news.is_headline);
      setIsActive(news.is_active);
      setImgTitle(news.img_title);
      setDatePublish(news.date_publish);
    }
  }, [news]);

  useEffect(() => {
    if (auth.user() !== null) {
      setUser(JSON.parse(auth.user()));
    }
    axios
      .get(`${process.env.REACT_APP_BASEURL}/admin/select2-category`)
      .then((res) => setOptionCategories(res.data.data))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (newsID !== null) {
      axios
        .get(`${process.env.REACT_APP_BASEURL}/admin/news-sub/${newsID}`)
        .then((res) => {
          setSections(res.data.data);
        })
        .catch((error) => console.log(error));
    }
  }, [newsID]);

  useEffect(() => {
    if (title !== null && title.length <= 200) {
      setRemainingTitle(200 - title.length);
    }
  }, [title]);

  useEffect(() => {
    if (teaser !== null && teaser.length <= 1500) {
      setRemainingTeaser(1500 - teaser.length);
    }
  }, [teaser]);

  const handleGetImg = async (e) => {
    const imageFile = e.target.files[0];
    const options = {
      maxSizeMB: 0.1,
      maxWidthOrHeight: 980,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      getBase64(compressedFile);
    } catch (error) {
      console.log(error);
    }
  };

  const getBase64 = (file) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setMainImage(reader.result);
      setReviewMainImage(reader.result);
    };
    console.log(reader);
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const handleSave = () => {
    setLabelSubmit("Loading...");
    setIsTitleError(false);
    setIsCategoryError(false);
    setIsContentError(false);
    if (!title) {
      setIsTitleError(true);
    }
    if (!category) {
      setIsCategoryError(true);
    }
    if (!content) {
      setIsContentError(true);
    }

    const data = {
      title: title,
      teaser: teaser,
      content: content,
      category_id: category,
      mainImage: mainImage,
      img_title: imgTitle,
      is_active: isActive,
      is_headline: isHeadline,
      news_sub: sections,
      date_publish: datePublish,
    };

    axios
      .put(`${process.env.REACT_APP_BASEURL}/admin/news/${newsID}`, data, {
        headers: { Authorization: "Bearer " + user.access_token },
      })
      .then((res) => {
        console.log(res.data);
        // setIsSuccessSave(true);
        setLabelSubmit("Simpan Artikel");
        props.history.push("/admin/profile");
      })
      .catch(() => {
        setLabelSubmit("Simpan Artikel");
        // setIsSuccessSave(false);
      });
  };

  const handleHeadline = (e) => {
    setIsHeadline(parseInt(e.target.value));
  };

  const handleActive = (e) => {
    setIsActive(parseInt(e.target.value));
  };

  const handleTitle = (e) => {
    setTitle(e.target.value);
    console.log(e.target.value);
    if (e.target.value.length > 0) {
      setIsTitleError(false);
    } else {
      setIsTitleError(true);
    }
  };

  const handleEditorChange = (content) => {
    setContent(content);
  };

  const handleAddSection = () => {
    const values = [...sections];
    values.push({ id: 0, title: "", content: "", img: "", img_md: "" });
    setSections(values);
  };

  const handleRemoveSection = (index) => {
    const values = [...sections];
    values.splice(index, 1);
    setSections(values);
  };

  const handleInputSection = (index, value, name) => {
    const values = [...sections];
    if (name === "title") {
      values[index].title = value;
    } else if (name === "img") {
      values[index].img = value;
    } else if (name === "content") {
      values[index].content = value;
    } else if (name === "del_img") {
      values[index].img = "";
      values[index].img_md = "";
    }
    setSections(values);
  };

  const handleRemoveImg = () => {
    setMainImage("");
    setReviewMainImage("");
  };

  return (
    <>
      <Header user={user} />
      <Container>
        <Row className={"justify-content-center"}>
          <Col xs md={10}>
            <div className={styles.wrapForm}>
              <div>
                <p>
                  <input
                    type="radio"
                    id="headline"
                    value={1}
                    checked={isHeadline === 1}
                    onChange={(e) => {
                      handleHeadline(e);
                    }}
                  />
                  <label htmlFor="headline">Headline</label>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <input
                    type="radio"
                    id="headline_n"
                    value={0}
                    checked={isHeadline === 0}
                    onChange={(e) => {
                      handleHeadline(e);
                    }}
                  />
                  <label htmlFor="headline_n">Bukan Headline</label>
                </p>
              </div>

              <div className={"mt-4"}>
                {optionCategories !== null && (
                  <Select
                    value={optionCategories.filter(
                      (option) => option.value === category
                    )}
                    placeholder="Pilih kategori berita"
                    onChange={(e) => {
                      setCategory(e.value);
                      setIsCategoryError(false);
                    }}
                    options={optionCategories}
                  />
                )}
                {isCategoryError && (
                  <p className={styles.messageError}>
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      className={"mr-2"}
                    />
                    Kategori berita jangan dikosongkan!
                  </p>
                )}
              </div>

              <div className={"mt-4"}>
                <TextareaAutosize
                  className={"form-control " + styles.inputForm}
                  onChange={(e) => handleTitle(e)}
                  placeholder={"Tulis judul kamu di sini"}
                  maxLength={200}
                  value={title}
                />
                <span className={styles.inputInfo}>
                  {remainingTitle}/200 Remaining
                </span>
                {isTitleError && (
                  <p className={styles.messageError}>
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      className={"mr-2"}
                    />
                    Judul berita jangan dikosongkan!
                  </p>
                )}
              </div>

              <div className={"mt-4"}>
                <TextareaAutosize
                  className={"form-control " + styles.inputTeaser}
                  onChange={(e) => setTeaser(e.target.value)}
                  placeholder={"Tulis cuplikan kamu di sini"}
                  maxLength={1500}
                  value={teaser}
                />
                <span className={styles.inputInfo}>
                  {remainingTeaser}/1500 Remaining
                </span>
              </div>

              <div className={"mt-4"}>
                {reviewMainImage && (
                  <>
                    <img
                      src={reviewMainImage}
                      className={styles.reviewImg}
                      accept="image/png, image/jpeg"
                      alt="img"
                    />
                    <button
                      className={"btn btn-danger " + styles.btnDeleteImg}
                      onClick={handleRemoveImg}
                    >
                      Hapus Gambar
                    </button>
                  </>
                )}
                <input
                  id="file"
                  type="file"
                  onChange={(e) => handleGetImg(e)}
                />
                <label htmlFor="file">Sisipkan Gambar</label>
              </div>

              <div className={"mt-4"}>
                <TextareaAutosize
                  className={"form-control " + styles.inputTeaser}
                  onChange={(e) => setImgTitle(e.target.value)}
                  placeholder={"Tulis keterangan foto di sini"}
                  maxLength={150}
                  value={imgTitle ? imgTitle : ""}
                />
                <span className={styles.inputInfo}>
                  Tuliskan caption untuk Gambar berita
                </span>
              </div>
              <div className={"mt-4"}>
                <Editor
                  apiKey="51nrqriju5la3cykjqqqxbxps70ns27nuak0lprrkossp2bt"
                  value={content}
                  init={{
                    height: 500,
                    menubar: true,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar: `paste pastetext | fontsizeselect formatselect forecolor blockquote | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat`,
                  }}
                  onEditorChange={(e) => handleEditorChange(e)}
                />

                {isContentError && (
                  <p className={styles.messageError}>
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      className={"mr-2"}
                    />
                    Isi berita jangan dikosongkan!
                  </p>
                )}
              </div>

              {sections.map((value, i) => (
                <SectionDesktopComponent
                  key={i}
                  i={i}
                  data={value}
                  handleRemoveSection={(e) => handleRemoveSection(e)}
                  handleInputSection={handleInputSection}
                />
              ))}

              <div>
                <button
                  className={styles.addSection + " float-right"}
                  title="Tambah Section"
                  type="button"
                  onClick={() => {
                    handleAddSection();
                  }}
                >
                  Tambah Section
                </button>
              </div>

              {user !== null &&
                (user.user.account_type === 1 ||
                  user.user.account_type === 2) && (
                  <div className={"mt-4"}>
                    <p>
                      <input
                        type="radio"
                        id="is_active_d"
                        value={2}
                        checked={isActive === 2}
                        onChange={(e) => {
                          handleActive(e);
                        }}
                      />
                      <label htmlFor="is_active_d">Draf</label>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <input
                        type="radio"
                        id="is_active"
                        value={1}
                        checked={isActive === 1}
                        onChange={(e) => {
                          handleActive(e);
                        }}
                      />
                      <label htmlFor="is_active">Tayang</label>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <input
                        type="radio"
                        id="is_active_n"
                        value={0}
                        checked={isActive === 0}
                        onChange={(e) => {
                          handleActive(e);
                        }}
                      />
                      <label htmlFor="is_active_n">Tidak Tayang</label>
                    </p>
                  </div>
                )}
              {isActive === 2 && (
                <div className={"mt-4"}>
                  <p className="mb-1">Tayang pada:{datePublish}</p>
                  <Datetime
                    dateFormat="YYYY-MM-DD"
                    timeFormat="h:mm:ss"
                    initialValue={datePublish}
                    onChange={(e) =>
                      setDatePublish(e.format("yyyy-MM-DD h:mm:ss"))
                    }
                  />
                </div>
              )}
              <div className={"mt-4"}>
                <Link
                  to="/admin/profile"
                  className={"btn btn-secondary mr-2 " + styles.btnCancel}
                >
                  Batal
                </Link>
                <button
                  type="button"
                  className={"btn btn-primary " + styles.btnSave}
                  onClick={handleSave}
                >
                  {labelSubmit}
                </button>
                {/* <SweetAlert
                  show={isSuccessSave}
                  title="Sukses"
                  text="Berita berhasil diupdate."
                  onConfirm={() => {
                    setIsSuccessSave(false)
                  }}
                /> */}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Edit;
