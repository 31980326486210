import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Header from '../../../components/admin/header/desktop/Header';
import { Container, Col, Row } from 'react-bootstrap';
// import Popular from '../../../components/admin/news/desktop/popular/Popular';
import auth from '../../../auth';
import axios from 'axios';

import styles from "./Ads.module.css"

const Ads = () => {
  const [user, setUser] = useState(null)
  const [ads, setAds] = useState(null)

  useEffect(() => {
    window.document.title = "Iklan | Bearita.com"

    if (auth.user() !== null) {
      setUser(JSON.parse(auth.user()));
    }

    axios.get(`${process.env.REACT_APP_BASEURL}/admin/ads`).then(res => {
      setAds(res.data.data)
    }).catch(error => console.log(error))
  }, [])

  return (
    <>
      <Header user={user} />
      <Container>
        <Row>
          <Col xs md={12}>
            <Link to="/admin/ads/create" className={"btn btn-primary my-3"}>Tambah Iklan</Link>
            <div className="table-responsive">
              <table className={'table table-striped '+styles.table}>
                <thead className="thead-light">
                  <tr>
                    <th className="text-center">Title</th>
                    <th className="text-center">URL</th>
                    <th className="text-center">Mulai</th>
                    <th className="text-center">Selesai</th>
                    <th className="text-center">Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {ads !== null ? (
                    ads.map((data_ads, i) => (
                      <tr key={i}>
                        <td style={{ width: "25%" }}>{data_ads.title}</td>
                        <td>{data_ads.url}</td>
                        <td className="text-center">{data_ads.start_date}</td>
                        <td className="text-center">{data_ads.end_date}</td>
                        <td style={{ width: "100px" }} className="text-center">{data_ads.is_active === 1 ? <span className="badge badge-success">Aktif</span> : <span className="badge badge-danger">Tidak Aktif</span>}</td>
                        <td style={{ width: "100px" }} className="text-center">
                          <Link to={`/admin/ads/edit/${data_ads.id}`} className={styles.linkTable}>Edit</Link>
                          <Link to={`/admin/ads/show/${data_ads.id}`} className={styles.linkTable}>Detail</Link>
                        </td>
                      </tr>
                    ))
                  ) :
                    <tr><td colSpan={6}>Belum ada iklan yang masuk</td></tr>}
                </tbody>
              </table>
            </div>
          </Col>
          {/* <Col xs md={4}>
            <div className="sticky-top">
              <Popular />
            </div>
          </Col> */}
        </Row>
      </Container>
    </>
  )
}

export default Ads
