import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { setupCache } from "axios-cache-adapter";

import TableListNews from "../table/TableListNews";

import styles from "./ListNews.module.css";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Rupiah } from "../../../../../utils/converter";

const ListNews = (props) => {
  const [news, setNews] = useState(null);
  const [search, setSearch] = useState(null);
  const [total_article, setTotal_article] = useState(0);
  const [total_hits, setTotal_hits] = useState(0);

  useEffect(() => {
    const cache = setupCache({
      maxAge: 15 * 60 * 1000,
    });

    const api = axios.create({
      adapter: cache.adapter,
    });

    if (props !== undefined && props.user) {
      api({
        method: "get",
        url: `${process.env.REACT_APP_BASEURL}/admin/my-news`,
        headers: { Authorization: `Bearer ${props.user.access_token}` },
      }).then(async (res) => {
        setNews(res.data.data);
        setTotal_article(res.data.total_article);
        setTotal_hits(res.data.total_hits);
      });
    }
  }, [props]);

  const handleSearch = () => {
    if (search !== null) {
      const cache = setupCache({
        maxAge: 15 * 60 * 1000,
      });

      const api = axios.create({
        adapter: cache.adapter,
      });

      api({
        method: "get",
        url: `${process.env.REACT_APP_BASEURL}/admin/search-news?q=${search}`,
        headers: { Authorization: `Bearer ${props.user.access_token}` },
      }).then(async (res) => {
        setNews(res.data.data);
      });
    }
  };
  return (
    <div className={styles.wrapListNews}>
      {news !== null && props !== undefined ? (
        <>
          <Container className="mb-3">
            <Row>
              <Col xs={6} style={{ paddingLeft: 0, paddingRight: "8px" }}>
                <Card>
                  <Card.Body>
                    <Card.Title className={styles.titleTotal}>
                      Total Artikel
                    </Card.Title>
                    <Card.Title className={styles.contentTotal}>
                      {Rupiah(total_article)}
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={6} style={{ paddingRight: 0, paddingLeft: "8px" }}>
                <Card>
                  <Card.Body>
                    <Card.Title className={styles.titleTotal}>
                      Total Dibaca
                    </Card.Title>
                    <Card.Title className={styles.contentTotal}>
                      {Rupiah(total_hits)}
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>

          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Cari berdasarkan Judul"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
            <div className="input-group-prepend">
              <span
                className="input-group-text"
                style={{ cursor: "pointer" }}
                onClick={handleSearch}
              >
                Cari
              </span>
            </div>
          </div>
          <TableListNews news={news} user={props.user} />
        </>
      ) : (
        <>
          <h1 className={styles.titleInfo}>
            Kamu belum menuliskan sesuatu nih.
          </h1>
          <p className={styles.subTitleInfo}>
            Yuk menulis, supaya halaman profile kamu lebih menarik lagi !
          </p>
          <Link to="/admin/create-article" className={styles.btnCreate}>
            Mulai Menulis
          </Link>
          <img
            src="https://www.idntimes.com/assets/img/empty/empty-profile.png"
            className={styles.imgInfo}
            alt="img"
          />
        </>
      )}
    </div>
  );
};

export default ListNews;
