import React, { useState, useEffect } from 'react'
import { Row, Col, Container } from 'react-bootstrap'

import auth from '../../../auth';
import Header from '../../../components/admin/header/desktop/Header'
import ListNews from '../../../components/admin/news/desktop/list/ListNews'
import Popular from '../../../components/admin/news/desktop/popular/Popular'

const Profile = () => {
  const [user, setUser] = useState(null)

  useEffect(() => {
    if (auth.user() !== null) {
      setUser(JSON.parse(auth.user()));
    }
  }, [])

  return (
    <>
      <Header user={user} />
      <Container>
        <Row>
          <Col xs={12} md={9}>
            {user ?
              <ListNews user={user} />
              : 'loading...'}
          </Col>
          <Col xs={12} md={3}>
            <div className="sticky-top">
              <Popular />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Profile
