import React, { useState, useEffect } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { Editor } from "@tinymce/tinymce-react";
import imageCompression from "browser-image-compression";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import styles from "../../../../../containers/admin/news/create/Create.module.css";

const Section = (props) => {
  const [img, setImg] = useState("");
  const [content] = useState("");
  const [idx, setIdx] = useState(0);

  useEffect(() => {
    if (props !== undefined) {
      setIdx(props.i);
    }
  }, [props]);

  const handleGetImgSection = async (e) => {
    console.log(props.i);
    const imageFile = e.target.files[0];
    const options = {
      maxSizeMB: 0.7,
      maxWidthOrHeight: 980,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      getBase64(compressedFile);
    } catch (error) {
      console.log(error);
    }
  };

  const getBase64 = (file) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setImg(reader.result);
      console.log(props.i);
      props.handleInputSection(props.i, reader.result, "img");
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  return (
    <>
      <button
        type="button"
        onClick={() => {
          props.handleRemoveSection(props.i);
        }}
        style={{ border: "transparent" }}
      >
        <FontAwesomeIcon icon={faTimes} className={styles.iconClose} />
      </button>
      <div className={"mt-4"}>
        <TextareaAutosize
          name="title"
          className={"form-control " + styles.inputFormSection}
          onKeyUp={(e) => {
            props.handleInputSection(props.i, e.target.value, "title");
          }}
          placeholder={`Tulis Sub-Judul Ke-${idx + 1} Di Sini`}
        />
      </div>

      <div className={"mt-4"}>
        {img && (
          <>
            <img
              src={img}
              className={styles.reviewImg}
              accept="image/png, image/jpeg"
              alt="img"
            />
            <button
              className={"btn btn-danger " + styles.btnDeleteImg}
              onClick={() => {
                setImg("");
                props.handleInputSection(props.i, "", "img");
              }}
            >
              Hapus Gambar
            </button>
          </>
        )}
        <input
          id={`img${props.i}`}
          type="file"
          onChange={(e) => handleGetImgSection(e)}
        />
        <label htmlFor={`img${props.i}`}>Sisipkan Gambar</label>
        <input type="hidden" name={`img${props.i}`} value={img} />
      </div>

      <div className={"mt-4"}>
        <Editor
          apiKey="51nrqriju5la3cykjqqqxbxps70ns27nuak0lprrkossp2bt"
          value={content}
          init={{
            height: 250,
            menubar: true,
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount",
            ],
            toolbar: `paste pastetext | fontsizeselect formatselect forecolor blockquote | bold italic backcolor | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat`,
          }}
          onEditorChange={(e) =>
            props.handleInputSection(props.i, e, "content")
          }
        />
      </div>
    </>
  );
};

export default Section;
