import React, { useState, useEffect } from 'react'
// import { Link } from 'react-router-dom'

import auth from '../../auth';
import Login from '../login/Login'
import Profile from '../admin/profile/Profile';

const Home = () => {
  const [user, setUser] = useState(null)

  useEffect(() => {
    if (auth.user() !== null) {
      setUser(JSON.parse(auth.user()))
    }
  }, [])

  return (
    <>
      {user !== null ? (
        <>
          <Profile/>
        </>
      ) : (
          <>
            <Login/>
          </>
        )
      }
    </>
  )
}

export default Home
