import React, { useEffect, useState } from 'react'
// import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import imageCompression from 'browser-image-compression';

import Select from 'react-select'
import DatePicker from "react-datepicker";
import SweetAlert from 'sweetalert2-react'

import styles from './Edit.module.css'
// import auth from '../../../../../auth';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import TextareaAutosize from 'react-textarea-autosize';
import { Row, Col } from 'react-bootstrap';

import "react-datepicker/dist/react-datepicker.css";
import auth from '../../../../../auth';
import { parseISO } from 'date-fns/esm';

const Create = (props) => {
  const [user, setUser] = useState(null)
  const [ads, setAds] = useState(null)
  const [adsID, setAdsID] = useState(null)

  const [optionAdsType, setOptionAdsType] = useState(null)
  const [adsType, setAdsType] = useState(null)
  const [adsTypeID, setAdsTypeID] = useState(null)
  const [isAdsTypeError, setIsAdsTypeError] = useState(false)
  const [title, setTitle] = useState(null)
  const [isTitleError, setIsTitleError] = useState(false)
  const [url, setUrl] = useState(null)
  const [price, setPrice] = useState(null)
  const [isActive, setIsActive] = useState(0)
  const [marketing, setMarketing] = useState(null)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [img, setImg] = useState(null)
  const [reviewImg, setReviewImg] = useState(null)

  const [isSuccessSave, setIsSuccessSave] = useState(false)
  const [labelSubmit, setLabelSubmit] = useState("Simpan Iklan")

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BASEURL}/admin/ads-type/for-form`).then(res => setOptionAdsType(res.data.data)).catch(error => console.log(error))
    if (auth.user() !== null) {
      setUser(JSON.parse(auth.user()));
    }
  }, [])

  useEffect(() => {
    if (props !== undefined) {
      setAds(props.ads)
      setAdsID(props.adsID)
    }
  }, [props])

  useEffect(() => {
    if (ads !== null) {
      setTitle(ads.title ? ads.title : '')
      setAdsType(ads.ads_type ? ads.ads_type : 0)
      setUrl(ads.url ? ads.url : '')
      setPrice(ads.price ? ads.price : '')
      setStartDate(ads.start_date_raw ? parseISO(ads.start_date_raw) : '')
      setEndDate(ads.end_date_raw ? parseISO(ads.end_date_raw) : '')
      setMarketing(ads.marketing ? ads.marketing : '')
      setIsActive(ads.is_active ? ads.is_active : 0)
      setReviewImg(ads.img_thumb)
    }
  }, [ads])

  const numeric = (e) => {
    let ch = String.fromCharCode(e.which);
    if (!/[0-9]/.test(ch)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (title === null) {
      setIsTitleError(false)
    } else if (title.length === 0) {
      setIsTitleError(true)
    } else {
      setIsTitleError(false)
    }
  }, [title])

  useEffect(() => {
    if (adsType !== null) {
      setAdsTypeID(adsType.id ? adsType.id : 0)
    }
  }, [adsType])

  const handleGetImg = async (e) => {
    const imageFile = e.target.files[0];
    const options = {
      maxSizeMB: 0.7,
      maxWidthOrHeight: 1024,
      useWebWorker: true
    }
    try {
      const compressedFile = await imageCompression(imageFile, options);
      getBase64(compressedFile);
    } catch (error) {
      console.log(error);
    }
  }

  const getBase64 = (file) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setImg(reader.result)
      setReviewImg(reader.result)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const handleSave = () => {
    setLabelSubmit("Loading...")
    setIsTitleError(false);
    if (!title) {
      setIsTitleError(true)
      setLabelSubmit("Update Iklan")
      return;
    }

    const data = {
      'ads_type_id': adsTypeID,
      'title': title,
      'url': url,
      'price': price,
      'start_date': startDate,
      'end_date': endDate,
      'is_active': isActive,
      'marketing': marketing,
      'img_base64': img
    }

    axios.put(`${process.env.REACT_APP_BASEURL}/admin/ads/${adsID}`, data, { 'headers': { 'Authorization': 'Bearer ' + user.access_token } })
      .then(() => {
        setIsSuccessSave(true)
        setLabelSubmit("Update Iklan")
        props.handleBack()
      }).catch(() => {
        setLabelSubmit("Update Iklan")
        setIsSuccessSave(false)
      })
  }

  return (
    <>
      <div className={styles.wrapForm}>
        <div>
          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label text-right">Jenis Iklan</label>
            <div className="col-sm-10">
              <Select value={adsType} placeholder="Pilih jenis iklan" onChange={e => {
                setAdsType(e)
                setIsAdsTypeError(false)
              }} options={optionAdsType} />
              {isAdsTypeError &&
                <p className={styles.messageError}>
                  <FontAwesomeIcon icon={faExclamationCircle} className={'mr-2'} />
                  Kategori berita jangan dikosongkan!</p>
              }
            </div>
          </div>
        </div>
        <div className={'mt-0'}>
          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label text-right">Title Iklan</label>
            <div className="col-sm-10">
              <TextareaAutosize value={title} className={"form-control " + styles.inputForm2} onKeyUp={(e) => {
                setTitle(e.target.value)
                if (e.target.value.length > 0) {
                  setIsTitleError(false)
                } else {
                  setIsTitleError(true)
                }
              }} placeholder={"Tulis title iklan disini"} maxLength={255} />
              {isTitleError ?
                <p className={styles.messageError}>
                  <FontAwesomeIcon icon={faExclamationCircle} className={'mr-2'} />
                  Title iklan jangan dikosongkan!</p>
                : ''}
            </div>
          </div>
        </div>
        <div className={'mt-0'}>
          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label text-right">URL Iklan</label>
            <div className="col-sm-10">
              <TextareaAutosize value={url} className={"form-control " + styles.inputForm2} onKeyUp={(e) => {
                setUrl(e.target.value)
              }} placeholder={"URL iklan disini, lengkap dengan http atau https. Misal: https://kukarkab.co.id"} maxLength={255} />
            </div>
          </div>
        </div>
        <div className={'mt-0'}>
          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label text-right">Harga Iklan</label>
            <div className="col-sm-10">
              <TextareaAutosize value={price} className={"form-control " + styles.inputForm2} onKeyUp={(e) => {
                setPrice(e.target.value)
              }} placeholder={"Harga iklan tulis disini (memudahkan proses rekapitulasi)"} maxLength={255} onKeyPress={e => { numeric(e) }} />
            </div>
          </div>
        </div>
        <div className={'mt-0'}>
          <Row>
            <Col sm={6}>
              <div className="form-group row">
                <label htmlFor="inputEmail3" className="col-sm-4 col-form-label text-right">Tanggal Mulai</label>
                <div className="col-sm-8">
                  <DatePicker
                    className={"form-control " + styles.inputForm2}
                    selected={(startDate !== null && startDate !== "Invalid Date") ? startDate : ""}
                    // selected={startDate}
                    // selected={startDate}
                    value={startDate}
                    onChange={(e) => { setStartDate(e) }}
                    type="text"
                    dateFormat="yyyy-MM-dd"
                  />
                </div>
              </div>
            </Col>
            <Col sm={6}>
              <div className="form-group row">
                <label htmlFor="inputEmail3" className="col-sm-4 col-form-label text-right">Tanggal Selesai</label>
                <div className="col-sm-8">
                  <DatePicker
                    className={"form-control " + styles.inputForm2}
                    selected={(endDate !== null && endDate !== "Invalid Date") ? endDate : ""}
                    value={endDate}
                    onChange={(e) => { setEndDate(e) }}
                    type="text"
                    dateFormat="yyyy-MM-dd"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className={'mt-0'}>
          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label text-right">Nama Marketing</label>
            <div className="col-sm-10">
              <TextareaAutosize value={marketing} className={"form-control " + styles.inputForm2} onKeyUp={(e) => {
                setMarketing(e.target.value)
              }} placeholder={"Tuliskan nama marketing"} maxLength={255} />
            </div>
          </div>
        </div>
        <div className={'mt-0'}>
          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label text-right">Gambar</label>
            <div className="col-sm-10">
              {reviewImg &&
                <>
                  <img src={reviewImg} className={styles.reviewImg} accept="image/png, image/jpeg" alt="img" />
                  <button className={'btn btn-danger ' + styles.btnDeleteImg} onClick={() => setImg("")}>Hapus Gambar</button>
                </>
              }
              <input id="file" type="file" onChange={e => handleGetImg(e)} />
              <label htmlFor="file">Sisipkan Gambar</label>
            </div>
          </div>
        </div>
        <div className={'mt-0'}>
          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label text-right">Status</label>
            <span className={"mx-3"}>
              <input className={styles.radio} type="radio" id="is_active" value={1} checked={isActive === 1} onChange={() => { setIsActive(1) }} />
              <label htmlFor="is_active">Aktif</label>
            </span>
            <span className={"mx-3"}>
              <input className={styles.radio} type="radio" id="is_active_n" value={0} checked={isActive === 0} onChange={() => { setIsActive(0) }} />
              <label htmlFor="is_active_n">Tidak Aktif</label>
            </span>
          </div>
        </div>
        <div className={'mt-0'}>
          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label text-right"></label>
            <div className="col-sm-10">
              <button type="button" className={'btn btn-primary ' + styles.btnSave} onClick={handleSave}>{labelSubmit}</button>
              <SweetAlert
                show={isSuccessSave}
                title="Sukses"
                text="Artikel berhasil dibuat."
                onConfirm={() => {
                  setIsSuccessSave(false)
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Create
