import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useHistory } from "react-router-dom";

import styles from './Login.module.css'
import axios from 'axios';

import auth from "../../auth"

const Login = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  // const [user, setUser] = useState("")
  const [isErrorLogin, setIsErrorLogin] = useState(false)
  const [messageLogin, setMessageLogin] = useState("")

  // const dataLocalUser = localStorage.getItem('c_user');

  // useEffect(() => {
  //   if (dataLocalUser !== null) {
  //     setUser(JSON.parse(atob(dataLocalUser)))
  //   }
  // }, [dataLocalUser])

  const history = useHistory();

  useEffect(() => {
    if (auth.isAuthenticated()) {
      // history.push("/admin/profile");
      window.location.href = "/admin/profile"
    }
  }, [history])

  const handleLogin = () => {
    axios.post(`${process.env.REACT_APP_BASEURL}/auth/login`, { 'email': email, 'password': password }).then(res => {
      // setUser(res.data)
      localStorage.setItem('c_user', btoa(JSON.stringify(res.data)))
      setIsErrorLogin(false)
      // history.push("/admin/profile");
      window.location.href = "/admin/profile"
    }).catch(function (error) {
      if (error.response) {
        setIsErrorLogin(true)
        setMessageLogin(error.response.data.message)
      }
    });
  }

  return (
    <Container>
      <Row className={'justify-content-center'}>
        <Col xs md={4}>
          <div id={styles.logregForms}>
            <form className={styles.formSignin}>
              <h1 className={'h3 mb-3 font-weight-normal text-center'}> Masuk {process.env.REACT_APP_APPNAME}</h1>
              <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className={styles.formControl + ' form-control'} placeholder="Email" required="" autoFocus="" />
              <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className={styles.formControl + ' form-control'} placeholder="Password" required="" />
              {isErrorLogin ?
                <div className={'alert alert-danger my-2'} role="alert">{messageLogin}</div>
                : ''}

              <button className={'btn btn-success btn-block mt-2'} type="button" onClick={() => handleLogin()}>Masuk</button>
              {/* <hr /> */}
              {/* <button className="btn btn-primary btn-block" type="button" id="btn-signup">Daftar Akun Baru</button> */}
            </form>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Login
