import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Header from '../../../components/admin/header/desktop/Header';
import { Container, Row, Col } from 'react-bootstrap';
import auth from '../../../auth';
import axios from 'axios';
import Detail from './card/Detail';

const ShowAds = ({ match }) => {
  const [user, setUser] = useState(null)
  const [ads, setAds] = useState(null)
  const [adsID, setAdsID] = useState(null)

  useEffect(() => {
    window.document.title = "Detail Iklan | Bearita.com"
    if (auth.user() !== null) {
      setUser(JSON.parse(auth.user()));
    }
  }, [])

  useEffect(() => {
    if (match.params.id) {
      setAdsID(match.params.id)
    }
  }, [match])

  useEffect(() => {
    if (adsID !== null) {
      axios.get(`${process.env.REACT_APP_BASEURL}/admin/ads/${adsID}`).then(res => {
        setAds(res.data.data)
      }).catch(error => console.log(error))
    }
  }, [adsID])

  return (
    <>
      <Header user={user} />
      <Container>
        <Row>
          <Col xs md={8}>
            <Link to="/admin/ads" className={"btn btn-outline-secondary my-3"}>Kembali ke Iklan</Link>
            <div className="table-responsive">
              <Detail ads={ads}/>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ShowAds
