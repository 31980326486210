import React from "react";
// import axios from 'axios';
import { Link } from "react-router-dom";

import "./style.css";
import { Table, Button } from "react-bootstrap";
import { Rupiah } from "../../../../../utils/converter";

const TableListNews = ({ news, user }) => {
  return (
    <>
      <Table responsive striped bordered hover className="mt-2">
        <thead>
          <tr>
            <th>Judul</th>
            <th>Kategori</th>
            <th>Terbit</th>
            <th className="text-center">Dibaca</th>
            <th style={{ width: "125px" }}></th>
          </tr>
        </thead>
        <tbody>
          {news &&
            news.map((data_news, i) => (
              <tr key={i}>
                <td>{data_news.title}</td>
                <td>{data_news?.category?.name}</td>
                <td>
                  {data_news.is_active === 1 ? (
                    <span className="badge badge-primary">tampil</span>
                  ) : (
                    <span className="badge badge-secondary">tidak</span>
                  )}
                </td>
                <td className="text-center">{Rupiah(data_news.hits)}</td>
                <td className="text-center">
                  {data_news.writer.id === user.user.id ||
                  user.user.account_type === 1 ||
                  user.user.account_type === 2 ? (
                    <Link to={`/admin/news/edit/${data_news.id}`}>
                      <Button variant="outline-primary" size="sm">
                        Edit
                      </Button>
                    </Link>
                  ) : (
                    ""
                  )}
                  <Link to={`/admin/news/${data_news.id}`}>
                    <Button variant="outline-info" size="sm">
                      Detail
                    </Button>
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
};

export default TableListNews;
