import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import styles from "./Header.module.css";
import { Row, Col, Container } from "react-bootstrap";

const Header = (props) => {
  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/login";
  };

  const [user, setUser] = useState();
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  useEffect(() => {
    if (props) {
      setUser(props?.user?.user);
    }
  }, [props]);

  const handleMenu = () => {
    setIsOpenMenu((isOpenMenu) => !isOpenMenu);
  };

  return (
    <>
      {user && (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className={"container"}>
            <Link to="/admin/profile" className="navbar-brand">
              {process.env.REACT_APP_APPNAME}
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={handleMenu}
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mr-auto">
                {user && user.account_type <= 2 && (
                  <li className="nav-item">
                    <Link to="/admin/ads" className="nav-link">
                      Iklan
                    </Link>
                  </li>
                )}
                <li className="nav-item">
                  <Link to="/admin/create-article" className="nav-link">
                    Tulis Berita
                  </Link>
                </li>
              </ul>
              <div className="my-2 my-lg-0">
                <ul className="navbar-nav mr-auto">
                  <li className="nav-item">
                    <Link to="/admin/profile" className="nav-link">
                      Profile
                    </Link>
                  </li>
                  <li className="nav-item">
                    <span
                      style={{ cursor: "pointer" }}
                      className="nav-link"
                      onClick={() => handleLogout()}
                    >
                      Logout
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      )}
      {isOpenMenu && (
        <div className={styles.wrapMobileNav}>
          <Container>
            <Row>
              <Col xs={11}>
                <h2 className={styles.menu}>MENU</h2>
                <ul className={styles.ulMobileNav}>
                  <li>
                    <Link to="/admin/create-article">Tulis Berita</Link>
                  </li>
                  <li>
                    <Link to="/admin/profile">Profile</Link>
                  </li>
                  <li onClick={() => handleLogout()}>Logout</li>
                </ul>
              </Col>
              <Col xs={1}>
                <span className={styles.close} onClick={handleMenu}>
                  X
                </span>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

export default Header;
