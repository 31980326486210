import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap';
// import axios from 'axios';
import auth from '../../../../auth';
import Header from '../../../../components/admin/header/desktop/Header';

import Create from './form/Create';

const ShowAds = (props) => {
  const [user, setUser] = useState(null)

  useEffect(() => {
    window.document.title = "Tambah Iklan | Bearita.com"
    if (auth.user() !== null) {
      setUser(JSON.parse(auth.user()));
    }
  }, [])

  const handleBack = (() => {
    props.history.push('/admin/ads')
  })

  return (
    <>
      <Header user={user} />
      <Container>
        <Row>
          <Col xs md={12}>
            <Link to={`/admin/ads`} className={"btn btn-outline-secondary my-3"}>Kembali ke Iklan</Link>
            <div className="table-responsive">
              <Create handleBack={() => handleBack()} />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ShowAds
