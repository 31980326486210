import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { setupCache } from 'axios-cache-adapter'
import renderHTML from 'react-render-html';
import { Link } from 'react-router-dom'

import auth from '../../../../auth';
import Header from '../../../../components/admin/header/desktop/Header';
import Popular from '../../../../components/admin/news/desktop/popular/Popular';

import styles from './Show.module.css';

const Show = ({ match }) => {
  const [user, setUser] = useState(null)
  const [news, setNews] = useState(null)
  const [newsID, setNewsID] = useState(null)

  useEffect(() => {
    if (auth.user() !== null) {
      setUser(JSON.parse(auth.user()));
    }
    setNewsID(match.params.id);
  }, [match])


  useEffect(() => {
    const cache = setupCache({
      maxAge: 15 * 60 * 1000
    })

    const api = axios.create({
      adapter: cache.adapter
    })

    if (user !== null) {
      api({
        method: 'get',
        url: `${process.env.REACT_APP_BASEURL}/news/${newsID}`
      }).then(res => {
        setNews(res.data.data)
      }).catch(error => console.log(error))
    }
  }, [user, newsID])

  return (
    <>
      <Header user={user} />
      <Container>
        <Row>
          <Col xs md={8}>
            {news !== null ?
              <div className={styles.my20}>
                <button className="btn btn-primary btn-sm mr-2"><Link to="/admin/profile" className="text-white">Kembali</Link></button>
                <button className="btn btn-warning btn-sm"><Link to={`/admin/news/edit/${news.id}`} className="text-white">Ubah</Link></button>
                <h1 className={styles.title+' mt-3'}>{news.title}</h1>
                {news.img_lg ?
                  <img src={news.img_lg} alt="img" className={styles.imgNews} />
                  : ''}
                {news.img_title ? <div className={styles.imgTitle}>{news.img_title}</div> : ''}
                {news.teaser ? <div className={styles.teaser}>{news.teaser}</div> : ''}
                {news.content ? <div className={"mt-3"}>{renderHTML(news.content)}</div> : ''}
              </div>
              : ''}
          </Col>
          <Col xs md={4}>
            <div className="sticky-top">
              <Popular />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Show
